<template>
  <v-card-title>
    <molecule-save />
    <v-spacer></v-spacer>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      @input="onInput"
    ></v-text-field>
  </v-card-title>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MoleculeSave from '../molecules/MoleculeSave.vue'
import { debounce } from 'lodash'

export default defineComponent({
  components: {
    MoleculeSave
  },
  data () {
    return {
      search: ''
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onInput: debounce(function (this: any, e: Event) {
      this.$emit('search', e)
    }, 200)
  }
})
</script>
