<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    no-click-animation
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="color" fab small dark class="mx-2" v-bind="attrs" v-on="on">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <AtomDialogForm
      :editProject="editProject || null"
      :toolbarColor="color"
      :dialog="dialog"
      v-if="dialog"
      @dialog="(data) => (dialog = data)"
      @project="(data) => (project = data)"
      @submit="submitForm"
    />
  </v-dialog>
</template>

<script lang="ts">
import AtomDialogForm from '../atoms/AtomDialogForm.vue'
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { toSnakeCase } from '@/utils/string-manipulation'
import { Project } from '@/store/models/projects'
import { saveMessage } from '@/utils/constants'

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: 'mdi-plus'
    },
    color: {
      type: String,
      default: 'primary'
    },
    editProject: {
      type: Object as () => Project | null
    }
  },
  components: {
    AtomDialogForm
  },
  data () {
    return {
      dialog: false,
      project: {} as Project,
      fileList: [] as Array<File>
    }
  },
  computed: {
    ...mapGetters({
      getPagination: 'project/getPagination'
    })
  },
  methods: {
    ...mapActions({
      setTableLoading: 'global/setTableLoading',
      setPageLoading: 'global/setPageLoading',
      fetchProjects: 'project/fetchProjects',
      saveProject: 'project/saveProject'
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    appendFormData (data: Project): FormData {
      const formData = new FormData()
      for (const [key, value] of Object.entries(data)) {
        const snakeCaseKey = toSnakeCase(key)

        if (Array.isArray(value)) {
          value.forEach((arrValue: any, index: any) => {
            formData.append(`${snakeCaseKey}[${index}]`, arrValue)
          })
        } else {
          formData.append(snakeCaseKey, value)
        }
      }
      return formData
    },
    submitForm (): void {
      const formData = this.appendFormData(this.project)

      if (confirm(saveMessage) === true) {
        this.setPageLoading(true)
        this.saveProject(formData)
          .then(() => {
            this.dialog = false
            this.setTableLoading(true)
            this.fetchProjects(this.getPagination).finally(() => {
              this.setTableLoading(false)
            })
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.errors) {
              const errorMessages = Object.values(error.response.data.errors).flat()
              const errorMessage = errorMessages.join('<br/>')
              this.$message({
                dangerouslyUseHTMLString: true,
                message: errorMessage,
                type: 'error'
              })
            } else {
              console.error('An error occurred:', error)
            }
          })
          .finally(() => {
            this.setPageLoading(false)
          })
      }
    }
  }
})
</script>
