<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    no-click-animation
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="color" fab small dark class="mx-2" v-bind="attrs" v-on="on">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <AtomDialogForm
      :editCustomerVoice="editCustomerVoice || null"
      :toolbarColor="color"
      :dialog="dialog"
      v-if="dialog"
      @dialog="(data) => (dialog = data)"
      @customerVoice="(data) => (customerVoice = data)"
      @submit="submitForm"
    />
  </v-dialog>
</template>

<script lang="ts">
import AtomDialogForm from '../atoms/AtomDialogForm.vue'
import { defineComponent } from 'vue'
import { CustomerVoice } from '@/store/models/customer-voice'
import { mapActions, mapGetters } from 'vuex'
import { toSnakeCase } from '@/utils/string-manipulation'
import { saveMessage } from '@/utils/constants'

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: 'mdi-plus'
    },
    color: {
      type: String,
      default: 'primary'
    },
    editCustomerVoice: {
      type: Object as () => CustomerVoice | null
    }
  },
  components: {
    AtomDialogForm
  },
  data () {
    return {
      dialog: false,
      customerVoice: {} as CustomerVoice
    }
  },
  computed: {
    ...mapGetters({
      getPagination: 'customerVoice/getPagination'
    })
  },
  methods: {
    ...mapActions({
      setTableLoading: 'global/setTableLoading',
      setPageLoading: 'global/setPageLoading',
      fetchCustomerVoices: 'customerVoice/fetchCustomerVoices',
      saveCustomerVoice: 'customerVoice/saveCustomerVoice'
    }),
    appendFormData (data: CustomerVoice): FormData {
      const formData = new FormData()
      for (const [key, value] of Object.entries(data)) {
        const snakeCaseKey = toSnakeCase(key)
        formData.append(snakeCaseKey, value)
      }
      return formData
    },
    submitForm (): void {
      const formData = this.appendFormData(this.customerVoice)

      if (confirm(saveMessage) === true) {
        this.setPageLoading(true)
        this.saveCustomerVoice(formData)
          .then(() => {
            this.dialog = false
            this.setTableLoading(true)
            this.fetchCustomerVoices(this.getPagination).finally(() => {
              this.setTableLoading(false)
            })
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.errors) {
              const errorMessages = Object.values(error.response.data.errors).flat()
              const errorMessage = errorMessages.join('<br/>')
              this.$message({
                dangerouslyUseHTMLString: true,
                message: errorMessage,
                type: 'error'
              })
            } else {
              console.error('An error occurred:', error)
            }
          })
          .finally(() => {
            this.setPageLoading(false)
          })
      }
    }
  }
})
</script>
