<template>
  <v-card>
    <OrganismHeader @search="(data) => (search = data)" />
    <OrganismContent :search="search" />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OrganismHeader from './organisms/OrganismHeader.vue'
import OrganismContent from './organisms/OrganismContent.vue'

export default defineComponent({
  name: 'ProjectTypeIndex',
  components: {
    OrganismHeader,
    OrganismContent
  },
  data () {
    return {
      search: ''
    }
  }
})
</script>
