<template>
  <v-card-text>
    <MoleculeTable :search="search" />
  </v-card-text>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MoleculeTable from '../molecules/MoleculeTable.vue'

export default defineComponent({
  props: {
    search: {
      type: String,
      required: false
    }
  },
  components: {
    MoleculeTable
  }
})
</script>
