<template>
  <v-card>
    <v-form ref="form" lazy-validation aria-autocomplete="off">
      <AtomToolBar
        :color="toolbarColor"
        @dialog="updateDialog"
        @submit="(data) => $emit('submit', data)"
      />
      <v-card-text class="pa-8">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <label>Project Type</label>
            <v-autocomplete
              name="projectType"
              v-model="project.projectTypeId"
              :items="getProjectTypes"
              :search-input.sync="search"
              clearable
              item-text="name"
              item-value="id"
              label=""
              dense
              outlined
              cache-items
              hide-details="auto"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <label>Title</label>
            <v-text-field
              v-model="project.title"
              label=""
              dense
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <label>Number of Developer</label>
            <v-text-field
              v-model="project.numberOfDevs"
              label=""
              dense
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <label>Description</label>
            <ElementTiptap
              @content="(data) => (project.description = data)"
              :editContent="editProject?.description ?? ''"
            />
          </v-col>
          <v-col cols="12">
            <label>Responsibilities</label>
            <v-card class="pa-8">
              <AddField v-model="project.responsibilities"></AddField>
            </v-card>
          </v-col>
          <v-col cols="12">
            <label>Qualifications</label>
            <v-card class="pa-8">
              <AddField v-model="project.qualifications"></AddField>
            </v-card>
          </v-col>
          <v-col cols="12">
            <label>Tech Used</label>
            <v-card class="pa-8">
              <AddField v-model="project.techUsed" :fields="project"></AddField>
            </v-card>
          </v-col>
          <v-col cols="12">
            <label>Skill</label>
            <v-card class="pa-8">
              <AddField v-model="project.skills"></AddField>
            </v-card>
          </v-col>
          <v-col cols="12">
            <label>Skill Sets</label>
            <v-card class="pa-8">
              <AddField v-model="project.skillSets"></AddField>
            </v-card>
          </v-col>
          <v-col cols="12">
            <label>Featured Image</label>
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :auto-upload="false"
              :file-list="fileList"
              list-type="picture"
              drag
              multiple
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                File of type: jpeg, png, jpg, gif, webp.
              </div>
            </el-upload>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AtomToolBar from './AtomToolBar.vue'
import { mapActions, mapGetters } from 'vuex'
import { Project } from '@/store/models/projects'
import ElementTiptap from '@/components/plugins/ElementTiptap.vue'
import AddField from '@/components/AddField.vue'
import routes from '@/utils/routes'
import instance from '@/plugins/axios'

export default defineComponent({
  components: {
    AtomToolBar,
    ElementTiptap,
    AddField
  },
  props: {
    toolbarColor: {
      type: String,
      default: 'primary'
    },
    dialog: {
      type: Boolean,
      default: false
    },
    editProject: {
      type: Object as () => Project | null
    }
  },
  watch: {
    project: {
      handler: function (newVal: Project) {
        this.$emit('project', newVal)
      },
      deep: true,
      immediate: true
    },
    fields: {
      handler (newFields) {
        this.$emit('projects', newFields)
      },
      deep: true
    },
    editProject: {
      handler: function (newVal) {
        if (newVal) {
          this.getImage(newVal.images)
          this.project.id = newVal.id
          this.project.projectTypeId = newVal.project_type_id
          this.project.title = newVal.title
          this.project.numberOfDevs = newVal.number_of_devs
          this.project.description = newVal.description
          this.project.responsibilities = newVal.responsibilities.split(' || ')
          this.project.qualifications = newVal.qualifications.split(' || ')
          this.project.techUsed = newVal.tech_used.split(' || ')
          this.project.skills = newVal.skills.split(' || ')
          this.project.skillSets = newVal.skill_sets.split(' || ')
        }
      },
      deep: true,
      immediate: true
    },
    search: {
      handler: async function (newVal) {
        const params = {
          search: newVal
        }
        this.isLoading = true
        await this.fetchProjectTypes(params).finally(() => {
          this.isLoading = false
        })
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      project: {} as Project,
      fileList: [] as Array<object>,
      allowedMimeTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
      isLoading: false,
      search: null
    }
  },
  computed: {
    ...mapGetters({
      getProjectTypes: 'projectType/getProjectTypes'
    })
  },
  methods: {
    ...mapActions({
      fetchProjectTypes: 'projectType/fetchProjectTypes',
      createProject: 'project/createProject',
      setLoading: 'global/setLoading'
    }),
    updateDialog (data: boolean): void {
      this.$emit('dialog', data)
    },
    async getImage (images: string) {
      const imagePathArray = images.split(' || ').map((img) => img.trim())

      this.fileList = []
      const imageFiles: Array<File> = []

      for (const path of imagePathArray) {
        const imageFile = await this.fetchImage(path)

        if (imageFile) {
          const fileObject = {
            name: path,
            url: `${routes.baseUrl}/storage/${path}`,
            file: imageFile
          }
          this.fileList.push(fileObject)
          imageFiles.push(imageFile)
        } else {
          console.error(`Failed to fetch image for path: ${path}`)
        }
      }

      this.project.images = imageFiles
    },
    async fetchImage (imagePath: string) {
      try {
        const response = await instance.get(`${routes.image}`, {
          responseType: 'blob',
          params: {
            imagePath: imagePath
          }
        })

        if (response.status === 200) {
          const blob = response.data

          if (this.allowedMimeTypes.includes(blob.type)) {
            const imageFile = new File([blob], imagePath, { type: blob.type })

            return imageFile
          } else {
            console.error('Fetched image has an invalid MIME type')
            return null
          }
        } else {
          console.error('Failed to fetch image:', response.status)
          return null
        }
      } catch (error) {
        console.error('Error fetching image:', error)
        return null
      }
    },
    handleChange (file: any) {
      if (!this.project.images) {
        this.project.images = []
      }

      if (file && file.raw) {
        this.project.images.push(file.raw)
      } else {
        console.error('File or file.raw is null:', file)
      }
    },
    handleRemove (file: any, fileList: Array<any>) {
      this.fileList = fileList

      if (Array.isArray(this.project.images)) {
        this.project.images = this.project.images.filter(
          (image) => image.name !== file.name
        )
      }
    }
  }
})
</script>
